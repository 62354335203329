import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import {setLast} from "../../dashboard/transaction.slice";


const TransactionSelector = () => {
    const transactions = useSelector(state => state.transactions.list)
    const dispatch = useDispatch()
    const last = useSelector(state => state.transactions.last)
    const [transact, setTransact] = useState("")
    const [list, setList] = useState([])

    useEffect(() => {
        setTransact(last.ID)
        setList(transactions)
    }, [transactions])
    const handleChange = (event) => {
        const selected = transactions.find(item => item.ID === event.target.value)
        dispatch(setLast(selected))
        setTransact(event.target.value)
    }

    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Anteriores</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={transact}
                label="Age"
                onChange={handleChange}
            >
                {
                    list.map(item => <MenuItem value={item.ID}>{dayjs(item.UpdatedAt).format('DD-MM-YY hh:mm')}</MenuItem>)
                }
            </Select>
        </FormControl>
    )
}

export default TransactionSelector