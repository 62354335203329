import React, {useEffect, useState} from "react";
import {Alert, Box, Button, FormControl, Snackbar, TextField} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {joinUser, keepAliveUser, registerUser} from "./login.slicer";
import Notify from "./notify";
import Logo from "../assets/pico256.png"

const Login = () => {
    const dispatch = useDispatch()
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [name, setName] = useState("")
    const [form, setForm] = useState("join")
    const [password, setPass] = useState("")
    const [secondPassword, setSecondPass] = useState("")
    const [errorPass, setErrorPass] = useState(false)
    const keepalive = useSelector(state => state.login.keepalive)
    const status = useSelector(state => state.login.status)
    const errorMsg = useSelector(state => state.login.errorMsg)
    const [openToastr, setOpenToastr] = React.useState(false);

    useEffect(() => {
        if (status === "failed") {
            handleClick()
        }
    },[status])

    const handleClick = () => {
        setOpenToastr(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToastr(false);
    };
    useEffect((() => {
        dispatch(keepAliveUser())
    }), [])

    const checkEmail = (value) => {
        const regEmail = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)
        setEmailError(!regEmail.test(value))
        setEmail(value)
    }

    const checkSecondPassword = value => {
        setErrorPass(password !== value)
        setSecondPass(value)
    }

    const join = () => {
        if (form === "join")
            dispatch(joinUser({email, password, company: "vistaactiva"}))
        else
            dispatch(registerUser({email, name, password, company: "vistaactiva"}))
    }

    const disableButton = () => {
        let disable = false

        if (form === "register" && secondPassword.length > 0 && errorPass)
            disable = true
        if (emailError)
            disable = true
        return disable
    }

    return (
        <div>
            {
                keepalive ? <Box
                        component="img"
                        alt="profile"
                        src={Logo}
                        height="256"
                        width="256"
                        borderRadius="50%"
                        sx={{objectFit: "cover"}}
                    /> :
                    <Box m="1.5rem 2.5rem"
                         display="flex"
                         flexDirection="column"
                         justifyContent="center"
                         alignItems="center"
                         gap={10}
                         minHeight="500px">
                        <Box
                            component="img"
                            alt="profile"
                            src={Logo}
                            height="218px"
                            width="218px"
                            // borderRadius="50%"
                            sx={{objectFit: "cover"}}
                        />
                        <div style={{flexDirection: "row", display: "flex", gap: 50}}>
                            <Button variant="outlined" onClick={() => setForm("join")}>LOGIN</Button>
                            <Button variant="outlined" onClick={() => setForm("register")}>REGISTRO</Button>
                        </div>
                        <FormControl sx={{width: '25ch'}}>
                            <TextField
                                label="Email"
                                id="standard-size-normal"
                                variant="standard"
                                error={emailError}
                                value={email}
                                onChange={el => {
                                    checkEmail(el.target.value)
                                }
                                }
                            />
                            {
                                form === "register" ? <div style={{height: 20}}></div>
                                    : null
                            }
                            {
                                form === "register" ?
                                    <TextField
                                        label="Nombre"
                                        id="standard-size-normal"
                                        variant="standard"
                                        value={name}
                                        onChange={el => {
                                            setName(el.target.value)
                                        }
                                        }
                                    /> : null
                            }
                            <div style={{height: 20}}></div>
                            <TextField
                                label="Password"
                                id="standard-size-normal"
                                variant="standard"
                                type="password"
                                value={password}
                                onChange={el => {
                                    setPass(el.target.value)
                                }
                                }
                            />
                            {
                                form === "register" ? <div style={{height: 20}}></div>
                                    : null
                            }
                            {
                                form === "register" ?
                                    <TextField
                                        label="Repeat password"
                                        id="standard-size-normal"
                                        variant="standard"
                                        type="password"
                                        error={errorPass}
                                        value={secondPassword}
                                        onChange={el => {
                                            checkSecondPassword(el.target.value)
                                        }
                                        }
                                    /> : null
                            }
                            <div style={{height: 20}}></div>
                            <Notify/>
                            <Button
                                disabled={disableButton()}
                                variant="outlined"
                                onClick={join}
                            >Entrar</Button>
                        </FormControl>
                    </Box>
            }

                <Snackbar open={openToastr} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {errorMsg && errorMsg.error.toLowerCase()}
                    </Alert>
                </Snackbar>
        </div>

    )
}

export default Login;