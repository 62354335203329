import { configureStore } from '@reduxjs/toolkit'
import headerReducer from './components/header/header.slice'
import recordersSlice from "./components/recorders/recorders.slice";
import loginSlice from "./components/login/login.slicer"
import transactionSlice from "./components/dashboard/transaction.slice";

const store = configureStore({
    reducer: {
        header: headerReducer,
        recorders: recordersSlice,
        login: loginSlice,
        transactions: transactionSlice
    }
})

export default store