import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {Files} from "../../core/endpoints";

export const getFiles = createAsyncThunk('recorders/getFiles', async (payload) => {
    const response = await Files.getAll(payload)
    return response
})

const initialState = {
    List: {},
    Recorders: [],
    recorderSelected: ""
};

const recordersSlice = createSlice({
    name: 'recorders',
    initialState,
    reducers: {
        selectRecorder(state, action) {
            state.recorderSelected = action.payload
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getFiles.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(getFiles.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched posts to the array
                state.List = action.payload.List
                state.Recorders = action.payload.Recorders
                console.log(state.recorderSelected)
                console.log(action.payload.Recorders)
                if (state.recorderSelected !== "" && action.payload.Recorders.length > 0) {
                    state.recorderSelected = action.payload.Recorders[0].Section
                }
            })
            .addCase(getFiles.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const {selectRecorder} = recordersSlice.actions
export default recordersSlice.reducer

export const selectRecorders = state => state.recorders.Recorders
export const isRecorderSelected = name => state => state.recorders.recorderSelected === name
export const selectFilesByRecorder = state => state.recorders.recorderSelected !== "" ? state.recorders.List[state.recorders.recorderSelected] : []
// export const selectFilesByRecorder = state =>  []
