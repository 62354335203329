import {useState} from "react";

class WsInstance {
    wslist = new Map()

    constructor(client) {
        // const url = "ws://localhost:4040/v1/ws";
        const url = "wss://campicker.es/v1/ws";

        if (typeof WsInstance.instance === "object")
            return WsInstance.instance
        this.c = new WebSocket(url)

        this.c.onopen = function () {
            const id = Math.random()
            this.send(id.toString())
        }

        // const url = this.getOrigin();
        WsInstance.instance = this
        return this
    }

    getOrigin() {
        const parse = window.location.origin.split(":")
        return `wss:${parse[1]}/v1/ws`
    }
}

const useWSocket = (client) => {
    const [data, setData] = useState({})
    const ws = new WsInstance(client)
    ws.c.onmessage = function (msg) {
        const raw = JSON.parse(msg.data)
        console.log(raw)
        if (raw.client === client)
            setData(raw)
    }

    return [data]
}

export default useWSocket