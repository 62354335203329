import {createSlice} from '@reduxjs/toolkit'

const headerSlice = createSlice({
    name: 'header',
    initialState: {
        mode: "dark"
    },
    reducers: {
        switchMode(state) {
            state.mode = state.mode === "light" ? "dark" : "light";
        },
    }
})

export const {switchMode} = headerSlice.actions
export default headerSlice.reducer