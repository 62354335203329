const API_ROOT = process.env.NODE_ENV === 'production' ? `${window.location.origin}/v1/` :
    // `http://localhost:4040/v1/`
        `https://campicker.es/v1/`


// function serialize(object) {
//     const params = [];
//
//     for (const param in object) {
//         if (Object.hasOwnProperty.call(object, param) && object[param] != null) {
//             params.push(`${param}=${encodeURIComponent(object[param])}`);
//         }
//     }
//
//     return params.join('&');
// }

const getToken = () => sessionStorage.getItem("token")
const setToken = (token) => sessionStorage.setItem("token", token)

const agent = async (url, body, method = 'GET') => {
    const headers = new Headers();
    const token = getToken()
    if (body) {
        headers.set('Content-Type', 'application/json');
    }

    if (token) {
        headers.set('Authorization', `Bearer ${token}`);
    }

    const response = await fetch(`${API_ROOT}${url}`, {
        method,
        headers,
        body: body ? JSON.stringify(body) : undefined,
    });
    let result;

    try {
        result = await response.json();
    } catch (error) {
        result = {errors: {[response.status]: [response.statusText]}};
    }
    if (!response.ok) throw result;
    if ("token" in result)
        setToken(result.token)
    return result;
};

const requests = {
    put: (url, body) => agent(url, body, 'PUT'),
    delete: (url, body) => agent(url, body, 'DELETE'),
    get: (url) => agent(url),
    post: (url, body) => agent(url, body, 'POST'),
};

export const Files = {
    getAll: (transactionId) => requests.get(`files/${transactionId}`),
    getDetails: (section, channel) => requests.get(`files/details/${section}/${channel}`),
    setDefault: (body) => requests.post(`files/setDefault`, body)
}

export const Igloos = {
    getAll: () => requests.get("igloo/company/vistaactiva")
}

export const Process = {
    start: (client) => requests.get(`server/?name=${client}`)
}

export const Sections = {
    create: (section) => requests.post(`sections/create`, section),
    update: (section) => requests.put(`sections/update`, section),
    delete: (section) => requests.delete(`sections/delete`, section)
}


export const Login = {
    join: (body) => requests.post(`login`, body),
    register: (body) => requests.post(`login/register`, body),
}

export const User = {
    keepalive: () => requests.get(`user/keepalive`),
    restorePass: (body) => requests.post(`user/restore-password`, body),
    getAll: () => requests.get(`user/getAll`)
}

