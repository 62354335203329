import React, {useCallback, useEffect, useRef, useState} from 'react';
import {styled} from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {green, red, yellow} from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useNavigate} from "react-router-dom";
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import useStart from "../../hooks/start";
import useWSocket from "../../hooks/websocket";
import {Alert, LinearProgress, Snackbar} from "@mui/material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {DataGrid, GridActionsCellItem} from '@mui/x-data-grid';
import {Sections} from "../../core/endpoints";
import DeleteIcon from '@mui/icons-material/Delete';
import {useDispatch} from "react-redux";
import {updateTransactions} from "./transaction.slice";

const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function ClientCard({ID, Name, Transaction, last, Status, Sections}) {
    const [expanded, setExpanded] = useState(false);
    const [_, totalCaptures, start] = useStart(Sections)
    const [fromServer] = useWSocket(ID)
    const navigate = useNavigate();
    const [avatar, setAvatar] = useState({
        bgcolor: red[500],
        active: "OFF"
    })
    const [progress, setProgress] = useState(0)
    const [finished, setFinished] = useState(0)
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch()

    useEffect(() => {
        if (Status === "on")
            setAvatar({
                bgcolor: green[500],
                active: "ON"
            })
    }, [Status])


    useEffect(() => {
        switch (fromServer.action) {
            case "start":
                setAvatar({
                    bgcolor: yellow[500],
                    active: "WORK"
                })
                break
            case "off":
                setAvatar({
                    bgcolor: red[500],
                    active: "OFF"
                })
                break
            case "counting" : {
                setFinished(fromServer.totalCaptures)
                setProgress(fromServer.totalCaptures * 100 / totalCaptures)
                break
            }
            case "stop" : {
                if (fromServer.transaction)
                    last.ID = fromServer.transaction
                setAvatar({
                    bgcolor: green[500],
                    active: "ON"
                })
                break
            }
        }
    }, [fromServer])

    const selectClient = () => {
        dispatch(updateTransactions(Transaction))
        navigate(`/recorders`);
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div>
            <Card sx={{width: 345}}>
                <CardHeader
                    avatar={
                        <Avatar sx={{bgcolor: avatar.bgcolor}} aria-label="recipe">
                            {avatar.active}
                        </Avatar>
                    }
                    action={
                        <IconButton aria-label="settings"
                                    onClick={() => selectClient(last.ID)}
                        >
                            <ArrowForwardIosIcon/>
                        </IconButton>
                    }
                    title={Name}
                />
                {/*<CardMedia*/}
                {/*    component="img"*/}
                {/*    height="194"*/}
                {/*    image="/static/images/cards/paella.jpg"*/}
                {/*    alt="Paella dish"*/}
                {/*/>*/}
                <CardContent>
                    <Typography>{`${finished} / ${totalCaptures}`}</Typography>
                    <LinearProgress variant="determinate" value={progress}/>
                </CardContent>
                <CardActions disableSpacing>
                    <IconButton aria-label="add to favorites" onClick={() => start(ID)}>
                        <PlayCircleFilledWhiteIcon/>
                    </IconButton>
                    {/*<IconButton aria-label="share">*/}
                    {/*    <ShareIcon />*/}
                    {/*</IconButton>*/}
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon/>
                    </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Button onClick={() => setOpen(true)}>Editar grabadores</Button>
                    </CardContent>
                </Collapse>
            </Card>

            <SectionsEdit open={open} setOpen={setOpen} data={Sections} id={ID}/>
        </div>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: "80%",
    width: "80%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const SectionsEdit = ({data, setOpen, open, id}) => {
    const [rows, setRows] = useState([])

    useEffect(() => {
        setRows(data)
    }, [data])

    useEffect(() => {
        const toCreate = rows.find(row => row.creating)
        if (toCreate)
            saveNewRow(toCreate)
    }, [rows])

    const getActions = ({row}) => {
        return [<GridActionsCellItem icon={<DeleteIcon/>} onClick={() => deleteRow(row)} label="Delete"/>]

    }

    const columns = [
        {field: 'Position', headerName: 'Orden', type: 'number', width: 80, editable: true, sort: 'asc'},
        {field: 'Section', headerName: 'Grabador', width: 180, editable: true},
        {field: 'User', headerName: 'User', width: 180, editable: true, hide: true},
        {field: 'Pass', headerName: 'Pass', width: 180, editable: true, hide: true},
        {field: 'Location', headerName: 'Ip', width: 180, editable: true},
        {field: 'Channels', headerName: 'Canales', type: 'number', width: 120, editable: true},
        {field: 'DiscardChannels', headerName: 'C. Anulados', width: 180, editable: true},
        {field: 'Active', width: 80, type: 'boolean', editable: true},
        {field: 'actions', width: 80, type: 'actions', getActions: getActions},
    ];


    const handleClose = () => setOpen(false);

    const [snackbar, setSnackbar] = useState(null);
    const [loading, setLoading] = useState(false)
    const handleCloseSnackbar = () => setSnackbar(null);

    const saveNewRow = useCallback((data) => {
        const id = data.id
        if (typeof data.DiscardChannels === "string")
            data.DiscardChannels = data.DiscardChannels.split(",")
        if (typeof data.Channels === "number")
            data.Channels = `${data.Channels}`
        setLoading(true)
        Sections.create(data)
            .then(response => {
                editingNewRow(response, id)
                setSnackbar({children: 'Section successfully saved', severity: 'success'});
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
                setLoading(false)
                setSnackbar({children: error.message, severity: 'error'});
            })

    }, [rows])

    const editingNewRow = (newRow, id) => {
        setRows(old => old.map(row => {
            if (row.id === id) {
                newRow.id = newRow.ID
                return {
                    ...newRow
                }
            }
            return row
        }))
    }

    const addNewRow = useCallback(() => {
        const row = {
            "id": "00000000-0000-0000-0000-000000000000",
            "ID": "00000000-0000-0000-0000-000000000000",
            "Hash": id,
            "Section": `nuevo_${rows.length}`,
            "ServerHost": "",
            "User": "",
            "Pass": "",
            "Location": "",
            "Channels": "",
            "DiscardChannels": null,
            "TotalChannels": 0,
            "Position": 0,
            creating: true
        }
        setRows(old => [
            ...old,
            row
        ])
    }, [rows])

    const deleteRow = (data) => {
        if (typeof data.DiscardChannels === "string")
            data.DiscardChannels = data.DiscardChannels.split(",")
        if (typeof data.Channels === "number")
            data.Channels = `${data.Channels}`
        setLoading(true)
        Sections.delete(data)
            .then(response => {
                setRows(old => old.filter(row => row.ID !== response.ID))
                setSnackbar({children: 'Section successfully saved', severity: 'success'});
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
                setLoading(false)
                setSnackbar({children: error.message, severity: 'error'});
            })
    }

    const processRowUpdate = useCallback(
        async (newRow) => {
            if (newRow.creating)
                return newRow;
            // Make the HTTP request to save in the backend
            if (typeof newRow.DiscardChannels === "string")
                newRow.DiscardChannels = newRow.DiscardChannels.split(",")
            if (typeof newRow.Channels === "number")
                newRow.Channels = `${newRow.Channels}`
            setLoading(true)
            const response = await Sections.update(newRow);
            setSnackbar({children: 'Section successfully saved', severity: 'success'});
            setLoading(false)
            response.id = response.ID
            editingNewRow(response, response.ID)
            return response;
        },
        [rows],
    );

    const handleProcessRowUpdateError = useCallback((error) => {
        console.log(error)
        setLoading(false)

        setSnackbar({children: error.message, severity: 'error'});
    }, []);

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{height: "100%", width: '100%'}}>
                        <DataGrid
                            rows={rows.map(item => {
                                item.id = item.ID
                                return item
                            })}
                            initialState={{
                                sorting: { sortModel: [{ field: "Position", sort: "asc" }] }
                            }}
                            columns={columns}
                            isCellEditable={(params) => params.row.section !== "default"}
                            experimentalFeatures={{newEditingApi: true}}
                            components={{
                                LoadingOverlay: LinearProgress,
                            }}
                            loading={loading}
                            processRowUpdate={processRowUpdate}
                            onProcessRowUpdateError={handleProcessRowUpdateError}
                        />
                        <Button onClick={addNewRow}>Añadir grabador</Button>
                    </div>
                </Box>
            </Modal>
            {!!snackbar && (
                <Snackbar
                    open
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    onClose={handleCloseSnackbar}
                    autoHideDuration={6000}
                >
                    <Alert {...snackbar} onClose={handleCloseSnackbar}/>
                </Snackbar>
            )}
        </div>
    );
}