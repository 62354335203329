import {useState} from "react";
import {Files} from "../core/endpoints";


const useSetDefault = () => {
    const [file, setFile] = useState({})
    const [reload, setReload] = useState(true)

    const setDefaultImage = (body) => {
        body.FileId= body.ID
        Files.setDefault(body)
            .then((data) => {
                console.log("SET DATA", data)
                setFile(data)
                setReload(reload => !reload)
                // fetchDefault(body)
            });
    }

    //
    // useEffect(() => {
    //     setDefaultImage(body)
    // })
    return [file, setDefaultImage, reload]
}

export default useSetDefault