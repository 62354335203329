import {LinearProgress, Typography, useTheme} from '@mui/material';
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";


const Notify = () => {
    const [error, setError] = useState(false)
    const status = useSelector(state => state.login.keep.status)
    const msg = useSelector(state => state.login.keep.errorMsg)
    const theme = useTheme();

    useEffect(() => {
        if (status === "failed") setError(true)
        if (status === "succeeded") setError(false)
        setError(false)
    }, [status])
    return (<div>
        {
            status === 'loading' ? <LinearProgress/> : null
        }
        {
            error ?
                <Typography variant="h5" color={theme.palette.secondary[300]}>
                    ERROR {msg}
                </Typography> : null
        }
    </div>)
}

export default Notify