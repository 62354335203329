import React, {useEffect} from "react";
import Box from '@mui/material/Box';
import useClients from "../../hooks/clients";
import ClientCard from "./card";
import {useMediaQuery} from "@mui/material";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import Typography from "@mui/material/Typography";

const Dashboard = () => {
    const [clients, reload] = useClients()
    const isRowBased = useMediaQuery('(max-width: 700px)');

    useEffect(() => {
            reload()
        }, []
    )

    return (
        <Box sx={{
            width: '100%',
            paddingTop: "70px",
            paddingLeft: isRowBased ? 0 : "10%",
            paddingRight: isRowBased ? 0 : "10%"
        }}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: isRowBased ? "center" : "flex-start",
                    gap: 20,
                    paddingTop: 50,
                    paddingBottom: 50,
                }}
            >
                <SupervisorAccountIcon fontSize={"large"}/>
                <Typography variant={"h4"}>
                    Clientes
                </Typography>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: isRowBased ? "center" : "flex-start",
                }}
            >
                {
                    clients.List.map((client, idx) =>

                        <ClientCard
                            key={idx}
                            {...client}
                        />
                    )
                }
            </div>
        </Box>
    )
}

export default Dashboard