import {createSlice} from '@reduxjs/toolkit'

const transactions = createSlice({
    name: 'header',
    initialState: {
        last: null,
        list: []
    },
    reducers: {
        updateTransactions(state, action) {
            console.log(action)
            state.last = action.payload[0]
            state.list = action.payload;
        },
        setLast(state, action) {
            state.last = action.payload
        }
    }
})

export const {updateTransactions, setLast} = transactions.actions
export default transactions.reducer