import React from "react"
import {ImagesGrid} from "./layout";
import {useSelector} from "react-redux";
import {selectFilesByRecorder} from "./recorders.slice";

const FilesGrid = () => {
    const images = useSelector(selectFilesByRecorder)

    return (
        <ImagesGrid images={images}/>
    )
}

export default FilesGrid