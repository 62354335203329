import {useState} from "react";
import {Process} from "../core/endpoints";


const useStart = (initialChannels) => {
    const countTotal = (res) => {
        let total = 0
        let discarded = 0
        res.forEach((recorder) => {
            total += +recorder.Channels
            if (recorder["DiscardChannels"])
                discarded += +recorder["DiscardChannels"].length
        })
        return total - discarded
    }

    const [end, setEnded] = useState(false)
    const [total, setTotal] = useState(countTotal(initialChannels))

    const startCapture = (client) => {
        Process.start(client)
            .then((res) => {
                setTotal(countTotal(res.List))
                setEnded(true)
            });
    }

    return [end, total, startCapture]
}

export default useStart