import React, {useMemo} from "react";
import './App.css';
import {Layout} from "./components/recorders/layout";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Dashboard from "./components/dashboard/dashboard";
import Details from "./components/details/details";
import {themeSettings} from "./theme";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import {useSelector} from "react-redux";
import Navbar from "./components/header/navbar";
import Login from "./components/login";

function App() {

    const mode = useSelector((state) => state.header.mode);
    const user = useSelector((state) => state.login.user);
    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

    return (
        <div className="App">
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <Navbar/>
                    <Routes>
                        <Route path="/" element={Object.keys(user).length === 0 ? <Login/> : <Dashboard/>}/>
                        <Route path="/recorders" element={<Layout/>}></Route>
                        <Route path="/details/:section/:channel" element={<Details/>}></Route>
                    </Routes>
                </ThemeProvider>
            </BrowserRouter>
        </div>
    );
}


export default App;
