import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {ImageList, ImageListItem, ImageListItemBar} from "@mui/material";
import src from "../../utils/ImageSrc";
import useWindowDimensions from "../../hooks/windowDimentions";
import useDetails from "../../hooks/details";
import {ImagesGrid} from "../recorders/layout";
import useSetDefault from "../../hooks/setDefault";
import {colorTokens} from "../../theme";

const Details = () => {
    const query = useParams();
    const params = {
        ...query,
        channel: +query.channel
    }
    const [{List, Default}, reloadFiles] = useDetails(params)
    // const [def, fetchDefault] = useGetDefault()
    const [setted, setDefaultImage, reloadDefaultImage] = useSetDefault()
    const [item, setItem] = useState({
        ...params,
        Url: ""
    })
    const [defaultItem, setDefaultItem] = useState({
        ...params,
        File: {Url: ""}
    })
    const {width} = useWindowDimensions();
    const [headerColumns, setHeaderColumns] = useState(2)
    const [listColumns, setListColumns] = useState(5)
    useEffect(() => {
        if (width < 1000) {
            setHeaderColumns(1)
            setListColumns(1)
        } else {
            setHeaderColumns(2)
            setListColumns(5)
        }
    }, [width])

    useEffect(() => {
        reloadFiles(params)
    }, [])

    useEffect(() => {
        if (List.length > 0)
            setItem({
                ...params,
                Url: List[0].Url || ""
            })
        // fetchDefault(params, List)
    }, [List])

    useEffect(() => {
        if (Default)
            setDefaultItem({
                ...params,
                ...Default.File
            })
    }, [Default])

    useEffect(() => {
        setDefaultItem({
            ...params,
            ...setted.File
        })
    }, [setted, reloadDefaultImage])

    // useEffect(() => {
    //     console.log(defaultItem);
    // }, [defaultItem])
    return (
        <div style={{paddingTop:64}}>
            <ImageList cols={headerColumns}>
                <ImageListItem sx={{maxWidth: 500, border: `2px solid ${colorTokens.primary[500]}` }} key={JSON.stringify(item)}>
                    <img
                        src={src(item)}
                        srcSet={src(item)}
                        alt={item.title}
                        loading="lazy"
                        onError={({currentTarget}) => {
                            currentTarget.onerror = null; // prevents looping
                            // currentTarget.src = `${window.location.origin}/notfound.jpeg`;
                        }}
                    />
                    <ImageListItemBar
                        title={item.channel}
                    />
                </ImageListItem>
                <ImageListItem sx={{maxWidth: 500, border: `2px solid ${colorTokens.primary[500]}`}} key={"item2"}>
                    <img
                        src={src(defaultItem)}
                        srcSet={src(defaultItem)}
                        alt={item.title}
                        loading="lazy"
                        onError={({currentTarget}) => {
                            currentTarget.onerror = null; // prevents looping
                            // currentTarget.src = `${window.location.origin}/notfound.jpeg`;
                        }}
                    />
                    <ImageListItemBar
                        title={"Default"}
                    />
                </ImageListItem>
            </ImageList>
            <ImagesGrid
                images={List}
                customClick={setDefaultImage}
            />
        </div>
    )
};
export default Details