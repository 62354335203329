const src = (item) => {
    // if (item.name.includes("Unauthorized"))
    //     return `${window.location.origin}/notfound.jpeg`
    // if (item.name.includes("Internal Server Error"))
    //     return `${window.location.origin}/notfound.jpeg`
    // if (item.name.includes("Bad Request"))
    //     return `${window.location.origin}/notfound.jpeg`
    // if (item.name.includes("default"))
    //     return `${window.location.origin}/${item.Url}`
    return `${window.location.origin}/v1/${item.Url}`
    // return `http://localhost:4040/${item.Url}`
}

export default src
