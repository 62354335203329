import React, {useState} from "react"
import "./menu.css"
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import IconButton from "@mui/material/IconButton";
import {useTheme} from "@mui/system";
import {Divider} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {isRecorderSelected, selectRecorder, selectRecorders} from "../recorders.slice";

const LateralMenu = ({collapsed}) => {
    const [collapse, setCollapse] = useState(true)
    const Recorders = useSelector(selectRecorders)

    const theme = useTheme();
    const mediumLight = theme.palette.neutral.light;
    const borderDark = theme.palette.primary.dark;

    const handlerCollapse = () => {
        collapsed(!collapse)
        setCollapse(!collapse)
    }

    return (
        <div
            className={`lateral-menu ${collapse ? "hide" : ""}`}
            style={{
                background: mediumLight,
                borderRight: `1px solid ${borderDark}`,
            }}
        >
            <div className={"collapse"}
                 style={{
                     background: mediumLight,
                     // borderRight: `1px solid ${borderDark}`,
                     // zIndex: 5
                 }}
            >
                <IconButton onClick={() => handlerCollapse()}>
                    {collapse ? <KeyboardDoubleArrowRightIcon/> : <KeyboardDoubleArrowLeftIcon/>}
                </IconButton>
            </div>
            <div
                style={{
                    overflow: "scroll",
                    overflowX: "hidden",
                    height: "calc(100vh - 290px)" // 152+64
                }}
            >
                {
                    Recorders && Recorders.map(recorder =>
                        <CardMenu recorder={recorder} key={recorder.ID} collapse={collapse}/>)
                }
            </div>

            <Divider orientation="vertical" flexItem/>

        </div>
    )
}

const CardMenu = ({recorder, collapse}) => {
    const dispatch = useDispatch()
    const isSelected = useSelector(isRecorderSelected(recorder.Section))

    const theme = useTheme();
    const altBackground = theme.palette.background.alt;
    const avatarBackground = theme.palette.primary.dark;

    const handlerSelection = () => {
        dispatch(selectRecorder(recorder.Section))
    }
    const channelsDiscard = !!recorder.DiscardChannels ? recorder.DiscardChannels.length : 0
    const totalChannels = recorder.Channels - channelsDiscard
    return (
        <div
            className={`card-menu`}
            style={{
                background: isSelected ? altBackground : "transparent"
            }}
            onClick={handlerSelection}
        >
            <Avatar sx={{background: avatarBackground}} src="/static/images/avatar/2.jpg">{recorder.Position}</Avatar>
            <div className={`card-title ${collapse ? "hide" : ""}`}>
                <Typography>{recorder.Section}</Typography>
                <Typography>{`Cámaras: ${totalChannels}`}</Typography>
            </div>
        </div>
    )
}

export default LateralMenu