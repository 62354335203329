import React, {useEffect, useState} from 'react'
import {AppBar, Card, CardMedia, Container, Menu, MenuItem, Switch, Toolbar} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Avatar from 'react-avatar';
import Grid from "@mui/material/Unstable_Grid2";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useDispatch, useSelector} from "react-redux";
import {switchMode} from "./header.slice";
import {keepAliveUser, logout} from "../login/login.slicer";
import Logo from "../assets/pico256.png";
import {useNavigate} from "react-router-dom";

const Navbar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mode = useSelector((state => state.header.mode))
    const user = useSelector((state => state.login.user))
    const [anchorElUser, setAnchorElUser] = useState(null);

    useEffect((() => {
        dispatch(keepAliveUser())
    }), [])

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
        navigate("/")
    };
    const handleLogout = () => {
        dispatch(logout())
        handleCloseUserMenu()
    };
    return (
        <AppBar position="fixed" sx={{display: Object.keys(user).length !== 0 ? "block" : "none"}}>
            <Container maxWidth={false}>
                <Toolbar disableGutters sx={{gap: 5}}>
                    <Card
                        sx={{display: 'flex', alignItems: "center", gap: 2}}
                        onClick={() => navigate("/")}
                    >
                        <CardMedia
                            component="img"
                            sx={{width: 50}}
                            image={Logo}
                            alt="Live from space album cover"
                        />
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: {xs: 'none', md: 'flex'},
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                                maxWidth: 500
                            }}
                        >
                            CamPicker
                        </Typography>
                    </Card>


                    <Box sx={{flexGrow: 1, display: {xs: 'flex'}, justifyContent: "center"}}>
                    </Box>
                    <Box>
                        <Grid container maxWidth={500} spacing={5}>
                            <Grid xs={2}>
                                <IconButton
                                    sx={{
                                        p: 0, display: {xs: 'flex', md: 'none'},
                                    }}
                                    onClick={handleOpenUserMenu}
                                >
                                    <Avatar name={user.name} size="32"/>

                                </IconButton>
                                <Box
                                    sx={{
                                        p: 0,
                                        display: {xs: 'none', md: 'flex'},
                                    }}
                                >
                                    <Avatar

                                        name={user.name} size="40"/>
                                </Box>

                            </Grid>
                            <Grid container spacing={0} xs={7} sx={{
                                display: {xs: 'none', md: 'flex'},
                            }}>
                                <Grid xs={12}>
                                    <Typography variant={"h6"}>{user.name}</Typography>
                                </Grid>
                                <Grid xs={12}>
                                    <Typography variant={"subtitle2"}>{user.company}</Typography>
                                </Grid>
                            </Grid>
                            <Grid xs={3}
                                  sx={{
                                      display: {xs: 'none', md: 'flex'},
                                  }}
                            >
                                <IconButton onClick={handleOpenUserMenu}>
                                    <MoreVertIcon/>
                                </IconButton>
                            </Grid>
                            <Menu
                                sx={{mt: '45px'}}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top', horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top', horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem key={"setting"} onClick={handleCloseUserMenu}>
                                    <Typography textAlign="center">{"setting"}</Typography>
                                </MenuItem>
                                <MenuItem>
                                    <Typography textAlign="center">{"Dark mode"}</Typography>
                                    <Switch checked={mode === "dark"} onClick={() => dispatch(switchMode())}
                                            size="small"/>
                                </MenuItem>
                                <MenuItem onClick={() => handleLogout()}>Log Out</MenuItem>

                            </Menu>
                        </Grid>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>);
}

export default Navbar