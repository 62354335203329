import {useState} from "react";
import {Files} from "../core/endpoints";


const useDetails = () => {
    const [files, setFiles] = useState({List: []})

    const reloadFiles = ({section, channel}) => {
        console.log(section, channel)
        Files.getDetails(section, channel)
            .then((data) => {
                setFiles(data)
                // fetchDefault(body)
            });
    }

    return [files, reloadFiles]
}

export default useDetails