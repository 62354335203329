import {useState} from "react";
import {Igloos} from "../core/endpoints"


const useClients = () => {
    const [clients, setClients] = useState({Count: 0, List: []})
    const reloadClients = () => {
        Igloos.getAll()
            .then((data) => {
                    data.List = data.List.map(client => {
                        client.last = client.Transaction[0]
                        return client
                    })
                    setClients(data)
                }
            )
    }
    return [clients, reloadClients]
}

export default useClients