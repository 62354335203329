import {ImageList, ImageListItem, ImageListItemBar, Typography, useMediaQuery} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import src from "../../utils/ImageSrc";
import Box from "@mui/material/Box";
import LateralMenu from "./menu/menu";
import "./recorder.layout.css"
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import {useDispatch, useSelector} from "react-redux";
import {getFiles} from "./recorders.slice";
import FilesGrid from "./grid";
import TransactionSelector from "./transactions/selector";

export const Layout = () => {
    const dispatch = useDispatch();
    const isRowBased = useMediaQuery('(max-width: 700px)');
    const last = useSelector(state => state.transactions.last)
    const [collapsedMenu, setCollapsedMenu] = useState(true)
    console.log("last", last)
    // const {transaction} = params
    useEffect(() => {
        dispatch(getFiles(last.ID))
    }, [last])
    const [expanded, setExpanded] = useState(0);
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const collapsed = (value) => {
        setCollapsedMenu(value)
    };
    return (
        <Box sx={{
            width: '100%',
            paddingTop: "70px",
            display: "flex",
            flexDirection: "column"
        }}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: isRowBased ? "center" : "flex-start",
                    gap: 20,
                    paddingLeft: isRowBased ? 0 : "10%",
                    paddingRight: isRowBased ? 0 : "10%",
                    paddingTop: 50,
                    paddingBottom: 50,
                }}
            >
                <SupervisorAccountIcon fontSize={"large"}/>
                <Typography variant={"h4"}>
                    Grabadores
                </Typography>
                <TransactionSelector/>
            </div>
            <Box sx={{
                width: '100%',
                display: "flex",
                flexDirection: "row"
            }}>
                <LateralMenu collapsed={collapsed}/>
                <div className={"recorders-grid"} style={{
                    paddingLeft: collapsedMenu ? 90 : 360
                }}>
                    <CreateEditSection/>
                    <FilesGrid/>
                </div>
            </Box>
        </Box>
    )
}

const CreateEditSection = () => {

    return (
        <div>

        </div>
    )
}

export const ImagesGrid = ({images, customClick}) => {
    let navigate = useNavigate();
    // const images = useSelector(selectFilesByRecorder)
    const isFour = useMediaQuery("(min-width: 1250px)");
    const isThree = useMediaQuery("(min-width: 1000px)");
    const isTwo = useMediaQuery("(min-width: 750px)");
    const isOne = useMediaQuery("(min-width: 500px)");
    const [imagesPerRow, setImagesPerRow] = useState(5)

    useEffect(() => {
        let count = 1
        if (isOne)
            count++
        if (isTwo)
            count++
        if (isThree)
            count++
        if (isFour)
            count++
        setImagesPerRow(count)
    }, [isFour, isThree, isTwo, isOne])


    const handlerClick = (item) => {
        if (!customClick)
            navigate(`/details/${item.Section}/${item.Channel}`)
        else
            customClick(item)
    }

    return (
        <ImageList cols={imagesPerRow}>
            {images.map((item) =>
                <ImageListItem key={item.ID}
                               onClick={() => {
                                   handlerClick(item)
                               }}>
                    <img
                        src={src(item)}
                        // srcSet={src(item)}
                        alt={item.ID}
                        loading="lazy"
                        onError={({currentTarget}) => {
                            currentTarget.onerror = null; // prevents looping
                            // currentTarget.src = `http://localhost:4040/captures/notfound.jpeg`;
                            currentTarget.src = `${window.location.origin}/notfound.jpeg`;
                        }}
                    />
                    <ImageListItemBar
                        title={item.Channel}
                    />
                </ImageListItem>
            )}
        </ImageList>
    )
}

