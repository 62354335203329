import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {Login, User} from "../../core/endpoints";


export const joinUser = createAsyncThunk('user/login', async (payload,{rejectWithValue}) => {
    let response
    try {
        response = await Login.join(payload)
        console.log(response)
    } catch (e) {
        console.log(e)
        return rejectWithValue(e)
    }
    return response
})

export const registerUser = createAsyncThunk('user/register', async (payload) => {
    const response = await Login.register(payload)
    return response
})

export const keepAliveUser = createAsyncThunk('user/keepalive', async (payload) => {
    const response = await User.keepalive()
    return response
})

const initialState = {
    mode: "dark",
    user: {},
    keepalive: true,
    keep: {
        status: "",
        errorMsg: null
    }
};

export const loginSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        setMode: (state) => {
            state.mode = state.mode === "light" ? "dark" : "light";
        },
        logout: (state) => {
            sessionStorage.setItem("token", null)
            state.user = {}
        }
    },
    extraReducers(builder) {
        builder
            .addCase(joinUser.pending, (state, action) => {
                state.status = 'loading'
                state.errorMsg = null
            })
            .addCase(joinUser.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.errorMsg = null
                state.user = action.payload
            })
            .addCase(joinUser.rejected, (state, action) => {
                console.log("joinUser.rejected", action.payload)
                state.status = 'failed'
                state.errorMsg = action.payload
            })
            .addCase(registerUser.pending, (state, action) => {
                state.status = 'loading'
                state.errorMsg = null
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.status = 'failed'
                state.errorMsg = {error: "espere a ser validado. gracias"}
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.status = 'failed'
                state.errorMsg = action.payload
            })
            .addCase(keepAliveUser.pending, (state, action) => {
                state.keepalive = true
                state.status = 'loading'
                state.errorMsg = null
            })
            .addCase(keepAliveUser.fulfilled, (state, action) => {
                state.keepalive = false
                state.status = 'succeeded'
                state.errorMsg = null
                state.user = action.payload
            })
            .addCase(keepAliveUser.rejected, (state, action) => {
                state.keepalive = false
                state.keep.status = 'failed'
                state.keep.errorMsg = action.payload
            })
    }
});

export const {setMode, logout} = loginSlice.actions;

export default loginSlice.reducer;
